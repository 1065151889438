@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Merriweather:wght@300&display=swap');

body {

    background-attachment: fixed;
    color: #000;
    font-family: sans-serif;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;

}

a {
    text-decoration: none;
    color: #000;

}

a:hover {
    text-decoration: none;
    color: #000;
}




  @media only screen and (max-width: 1099px) {
    .app {

        background-image: url('./assets/portfolio-bg-2022.svg');
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    
    }
  }

  
  /* Large devices (laptops/desktops, 1024px and up) */
  @media only screen and (min-width: 1100px) {
    .app {
        background-image: url('./assets/portfolio-bg-2022.svg');
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    }
  }

.app__container {
    
  margin-top: 30px;
  margin-bottom: auto;
  
}

/* @media only screen and (max-width: 992px) {
    .app__container {
    
        margin-top: 30px;
        margin-bottom: auto;
        zoom: 80%;
        
      }
} */

/* @media only screen and (max-width: 575px) {
    .app__container {
    
        margin-top: 30px;
        margin-bottom: auto;
        padding-left: 240px;
        padding-right: 240px;
        zoom: 80%;
      }
}

@media only screen and (max-width: 1199px) {
    .app__container {
    
        margin-top: 30px;
        margin-bottom: auto;
        padding-left: 20px;
        padding-right: 20px;
        zoom: 80%;
      }
} */


@media only screen and (min-width: 1200px) and (min-height: 700px) {
    .app__container {
    
        margin-top: 30px;
        margin-bottom: auto;
        zoom: 100%;
        
      }
}

@media only screen and (min-width: 1320px) and (min-height: 790px) {
    .app__container {
    
        margin-top: 50px;
        margin-bottom: auto;
        zoom: 110%;
        
      }
}

@media only screen and (min-width: 1420px) and (min-height: 860px) {
    .app__container {
    
        margin-top: 40px;
        margin-bottom: auto;
        zoom: 120%;
        
      }
}

@media only screen and (min-width: 1520px) and (min-height: 930px) {
    .app__container {
    
        margin-top: 45px;
        margin-bottom: auto;
        zoom: 130%;
        
      }
}

@media only screen and (min-width: 1720px) and (min-height: 1000px) {
    .app__container {
    
        margin-top: 45px;
        margin-bottom: auto;
        zoom: 140%;
        
      }
}

@media only screen and (min-width: 1920px) and (min-height: 1100px) {
    .app__container {
    
        margin-top: 50px;
        margin-bottom: auto;
        zoom: 150%;
        
      }
}

@media only screen and (min-width: 2120px) and (min-height: 1200px) {
    .app__container {
    
        margin-top: 55px;
        margin-bottom: auto;
        zoom: 160%;
        
      }
}



@media only screen and (max-width: 1199px) {
    .row_flex {
        flex: none;
    }
} 


.sidebar__social-icons {
    padding-left: 10px;
}


.app__main-content {
    /* background-color: #ffffff; */
    /* background-image: url('./assets/backgs/narcissus-yellow-flower-by-Vexels.svg'); */
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 25px 15px;
    border: 5px solid #000;
    /* box-shadow: 0 0 10px  rgba(138, 138, 138, 0.644); */
}

/* .about__intro {
    font-size: 240px;
} */

/* list */
.ulx {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ulx:before {
    content: "";
    display: inline-block;
    width: 2px;
    background: #979797;
    position: absolute;
    left: 3px;
    top: 5px;
    height: calc(100% - 40px);
  }
  
  .lix {
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
  }
  .lix:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #979797;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 10px;
  }

/* sidebar */
.sidebar {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    padding: 25px 15px;
    /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.644); */
    border: 5px solid #000;
    text-align: center;
}

.sidebar__avatar {
    height: 170px;
    width: 170px;
    object-fit: contain;
    border-radius: 30px;
}

.sidebar__icon {
    width: 25px;
    height: 25px;
}

.sidebar__icon:hover {
    transform: scale(1.1);
}

.sidebar__name {
    font-family: 'Arial';
    margin: 20px 0;
    font-size: 1.6rem;
    font-weight: bold;
}

.sidebar__name>span {
    font-weight: bold;
    font-size: 1.6rem;
    font-family: 'Arial';
}

.sidebar__item {
    margin-bottom: 6px;
    padding-top: 6px;
    height: 40px;
    font-size: 1.1rem;
    border-radius: 30px;
}

.sidebar__title {
    background-color: #ECEFF1;
    border-radius: 30px;
    font-size: 1.2rem;
}

.sidebar__contact {
    padding: 2px 0;
    margin: 8px -15px;

}

.sidebar__location {
    padding: 3px;

}

.sidebar__email {
    background-color: #BBDEFB;
    border-radius: 30px;
    color: #093a85;
    margin: 15px 25px 0px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.sidebar__resume {
    padding-top: 8px;
    background-color: #C8E6C9;
    transition: all 0.2s linear;
    color: #0d3b10;
}

.sidebar__email:hover {
    background-color: #90CAF9;
    color: #000;
    transition: all 0.2s linear;

}


.sidebar__resume:hover {
    background-color: #A5D6A7;
    color: #000;
    transition: all 0.2s linear;
}


@media only screen and (max-width: 991px) {
    .app__main-content {
        margin: 30px;
    }
}
/* navbar */

.navbar__items {
    display: flex;
    /* border-top: 40px solid transparent; */
    /* border-bottom: 40px solid transparent; */
}

.navbar__active {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    border-bottom: 4px solid#ffcc29;
}

.navbar__item {
    margin-left: 20px;
    text-decoration: none;
    font-size: 1.3rem;
    cursor: pointer;
    border-radius: 30px;
    padding: 5px 16px;
    border: 4px solid #000;
    text-align: center;
}

.navbar__item:hover {
    background-color: #BBDEFB;
    color: #093a85;
    padding: 9px 20px;
    border: 0px solid #000;
}

/* about */

.about {
    padding: 20px;
    margin: -35px;
}

.about__intro {
    font-size: 1rem;
    padding: 20px 28px;
    margin: 10px 0;
}

/* .about__container {
    background-color: #bba218;
    padding: 3px 1px;
} */

.about__heading {
    font-size: 25px;
    font-weight: 700;
    color: #000;
    margin-left: 10px;
}

.face {
    height: 27px;
    padding-bottom: 7px;
}

/* skill-card */
.skill-card {
    background-color: #ECEFF1;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
}

.skill-card__icon {
    width: 40px;
    height: 40px;
}

.skill-card-body {
    margin-left: 15px;
}

.skill_card__content {
    margin: 0;
    font-size: 0.7rem;
    border-radius: 100px;
    border: 1.5px solid #000;
    padding: 3px 4px;
    text-align: center;
    display: inline;
}

/* resume */

.resume {
    padding-top: 20px;
    height: 510px;
    overflow-y: auto;
}

.resume-card__heading,
.reume-language__heading {
    margin-left: 0px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #000;
}

.resume-card__body {
    margin-top: 10px;
    
}

.resume-card__title {
    font-weight: 700;
}

.resume-card__name {
    font-size: 16px;
}

.resume-card__details {
    font-size: 0.9rem;
    padding-top: 5px;
}

/* bar */
.bar {
    width: 90%;
    background-color: #CFD8DC;
    border-radius: 20px;
    margin-bottom: 12px;
}

.bar__icon {
    width: 15px;
    height: 15px;
}

.bar__wrapper {
    background-color: #000;
    border-radius: 20px;
    padding: 4px 14px;
    color: #fff;
    font-size: 0.9rem;
}

/* projects */
.projects {
    height: 510px;
    padding-top: 30px;
    overflow-y: auto;
}

.projectCard__wrapper {
    margin: 10px 0;
    width: 100%;
    height: 100%;
    min-height: 160px;
}

.projectCard__image {
    width: 100%;
    height: 75%;
    border-radius: 15px;
    border: 3px solid#ffcc29;
    transition: transform 0.3s linear;
}

.projectCard__image:hover {
    transform: scale(1.05);
}

.projectCard__icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.projectCard__title {
    margin-top: 12px;
    font: size 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects__navbar {
    display: flex;
    margin-bottom: 5px;

}
@media only screen and (max-width: 1099px) {
    .projects__navbar {
        display: none;
        margin-bottom: 5px;
    }
    .about {
        padding: 20px;
        margin: -35px;
    }
}
.projects__navbar>div {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
}

.projects__navbar-active {
    color: #ffcc29;
    text-decoration: underline 2px;
    text-underline-offset: 3px;
    text-decoration-color: #2e2e2e;
}

@media (max-width: 991px) {
    .app__main-content {
        margin-right: 15px;
        margin-left: 15px;
    }

    .navbar__active {
        font-size: 1.5rem;
    }

    .navbar__item {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .app__container {
        box-sizing: content-box;
        margin-top: 30px;
        margin-bottom: auto;
        zoom: 90%;
      }
    .sidebar {
        width: 260px;
        margin-left: -40px;
    }
    .about__container {
        height: 300px;
        width: 96%;
        overflow-y: auto;
    }
    .col-flat {
        flex: none;
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .navbar__item {
        margin-left: 20px;
        text-decoration: none;
        font-size: 0.8rem;
        cursor: pointer;
        border-radius: 30px;
        padding: 2px 10px;
        border: 3px solid #000;
        text-align: center;
    }

    .navbar__item:hover {
        background-color: #BBDEFB;
        color: #093a85;
        padding: 5px 13px;
        border: 0px solid #000;
    }
}

@media only screen and (max-width: 367px) {
    .navbar__item {
        margin-left: 10px;
        text-decoration: none;
        font-size: 0.8rem;
        cursor: pointer;
        border-radius: 30px;
        padding: 2px 8px;
        border: 3px solid #000;
        text-align: center;
    }

    .navbar__item:hover {
        background-color: #BBDEFB;
        color: #093a85;
        padding: 5px 11px;
        border: 0px solid #000;
    }
}
